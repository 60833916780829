<template>
  <div>
    <div class="row">
      <div class="col-12 mb-4">
        <h4 class="blue-text font-weight-bold">
        <router-link :to="{ name: 'adminRoute-users-list' }">
          <font-awesome-icon
            class="mr-3"
            :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
            />
          </router-link>
        <span>依頼詳細を編集</span>
        </h4>
      </div>
    </div>
    <form v-on:submit="updateUserProfile">
      <div class="form-group row">
        <label for="id" class="col-sm-3 col-form-label">ユーザーID:</label>
        <div class="col-sm-9">
          <input type="text" v-model="usersData.id" name="id" id="id" class="form-control" disabled>
        </div>
      </div>
      <div class="form-group row">
        <label for="last_name" class="col-sm-3 col-form-label">名前(姓):</label>
        <div class="col-sm-9">
          <input type="text" name="last_name" id="title" v-model="lastName" class="form-control">
        </div>
      </div>
      <div class="form-group row">
        <label for="first_name" class="col-sm-3 col-form-label">名前(名):</label>
        <div class="col-sm-9">
          <input type="text" name="first_name" id="first_name" v-model="firstName" class="form-control">
        </div>
      </div>
      <div class="form-group row">
        <label for="email" class="col-sm-3 col-form-label">メールアドレス:</label>
        <div class="col-sm-9">
          <input type="text" name="email" id="email" v-model="email" required class="form-control">
        </div>
      </div>
      <div class="form-group row">
        <label for="tel" class="col-sm-3 col-form-label">電話番号:</label>
        <div class="col-sm-9">
          <input type="text" name="tel" id="tel" v-model="tel" class="form-control">
        </div>
      </div>
      <div class="form-group row">
        <label for="company_name" class="col-sm-3 col-form-label">会社名:</label>
        <div class="col-sm-9">
          <input type="text" name="company_name" id="company_name" v-model="company_name" class="form-control">
        </div>
      </div>
      <div class="form-group row">
        <label for="birth_date" class="col-sm-3 col-form-label">生年月日:</label>
        <div class="col-sm-9">
          <v-date-picker
            v-model="birthDate"
            :masks="{ L: 'YYYY-MM-DD' }"
            locale="ja"
            mode="date"
            :max-date="new Date()"
          >
          <template v-slot="{ inputValue, togglePopover }">
            <div class="date-picker-wrap">
              <input
                :value="inputValue"
                v-on:click="togglePopover"
                class="form-control datepicker-input shadow-1"
                placeholder="YYYY-MM-DD"
                required
              />
            </div>
          </template>
        </v-date-picker>
        </div>
      </div>
      <div class="form-group row">
        <label for="gender" class="col-sm-3 col-form-label">性別:</label>
        <div class="col-sm-9">
          <b-form-select
            class="shadow-1"
            v-model="gender"
            :options="getGender()"
            required
          ></b-form-select>
        </div>
      </div>
      <div class="form-group row">
        <label for="prefectures" class="col-sm-3 col-form-label">都道府県:</label>
        <div class="col-sm-9">
          <b-spinner small v-if="prefectures.loader"></b-spinner>
          <b-form-select
            class="shadow-1"
            v-model="prefectures.value"
            :options="prefectures.options"
            required
          ></b-form-select>
        </div>
      </div>

      <div class="form-group row">
        <label for="tags" class="col-sm-3 col-form-label">ユーザタグ:</label>
        <div class="col-sm-9">
          <b-spinner small v-if="tags.loader"></b-spinner>
          <b-form-select
            class="shadow-1"
            v-model="tags.value"
            :options="tags.options"
          ></b-form-select>
        </div>
      </div>
      <div class="form-group row">
        <label for="is_invoice_payment" class="col-sm-3 col-form-label">支払い方法:</label>
        <div class="col-sm-9">
          <b-form-select
            class="shadow-1"
            v-model="is_invoice_payment"
            :options="getInvoice()"
            required
          ></b-form-select>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-12 mt-5">
        <router-link
          :to="{
            name: 'adminRoute-dashboard',
          }"
        >
          <button type="button" class="btn btn-ds grey white-text">キャンセル</button>
        </router-link>
      </div>
      <div class="col-12 col-md-12 col-lg-12 mt-5">
        <button
          class="btn btn-ds dark-blue white-text"
          type="submit"
          v-waves.light
        >
          更新
        </button>
      </div>
    </form>
    <b-modal
    id="user-profile-update-success-modal"
    class="border-radius-2"
    size="md"
    hide-footer
    hide-header
    no-close-on-backdrop
    no-close-on-esc
    centered
  >
    <h5
      class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
      v-on:click="$bvModal.hide('user-profile-update-success-modal')"
    >
      <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
    </h5>
    <div class="container text-center">
      <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
      <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
        プロフィールが更新されました
      </h5>
      <div class="row mb-3 mt-5">
        <div class="col-12">
          <button
            class="btn btn-ds dark-blue white-text pt-2 pb-2"
            type="button"
            v-waves.light
            v-on:click="$bvModal.hide('user-profile-update-success-modal')"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </b-modal>
  </div>
</template>
<script>
    // Import Vuex/Store
    import { mapGetters } from 'vuex';
    import { adm_userService_GetDetailsByID } from '../../services/admin/users';
    import { getGender } from '../../common/Utility';
    import { getInvoice } from '../../common/Utility';
    import moment from 'moment';
    import { adm_userService_UpdateUserProfile } from '../../services/admin/users';
    import DatePicker from 'v-calendar/lib/components/date-picker.umd';
    import {
      globalService_GetAllPrefectures,
    } from '../../services/global';

    export default {
      components: {
        'v-date-picker': DatePicker,
      },
      data() {
        return {
          id: null,
          firstName: null,
          lastName: null,
          email: null,
          tel: null,
          company_name: null,
          birthDate: null,
          gender: null,
          payment: null,
          prefectures: {
            value: null,
            options: [],
            loader: true,
          },
          tags: {
            value: null,
            options: [],
            loader: true,
          },
          tag_id: null,
          is_invoice_payment: null,
          search: null,
          usersData: null,
          prefecture: null,
        };
      },
      mounted() {
        this.setUserData();
        this.retievePrefectures();
      },
      computed: {
        ...mapGetters(['currentUser']),
      },
      methods: {
        getInvoice,
        getGender,
        setUserData() {
          let _this = this;

          let accessToken = localStorage.getItem('_accessToken');
          let userID = location.pathname.split('/admin/users/edit/')[1];

          adm_userService_GetDetailsByID({ id: userID, token: accessToken })
          .then((response) => {
              let data = response.data.result;
              console.log(data);
      

              _this.usersData = data;

              if (_this.usersData.user_profile) {
                _this.firstName = _this.usersData.user_profile.first_name;
                _this.lastName = _this.usersData.user_profile.last_name;
                _this.email = _this.usersData.email;
                _this.tel = _this.usersData.user_profile.tel;
                _this.company_name = _this.usersData.user_profile.company_name;
                _this.birthDate = _this.usersData.user_profile.birth_date;
                _this.gender = _this.usersData.user_profile.gender;
                _this.prefectures.value = _this.usersData.user_profile.prefectures;
                _this.tags.value = _this.usersData.user_profile.tag_id;
                _this.is_invoice_payment = _this.usersData.user_profile.is_invoice_payment
              }

              if (data.user_profile) {
                _this.formatPrefectures(data.user_profile.prefectures);
              }
              _this.setTags();
          })
          .catch((error) => {
              console.log(error);
          });
        },
        retievePrefectures() {
          let _this = this;

          _this.prefectures.loader = true;

          globalService_GetAllPrefectures()
            .then((response) => {
              let data = response.data;

              let prefectures = [];
              prefectures.push({
                value: null,
                text: '都道府県を選択してください',
                disabled: true,
              });

            data.forEach((el) => {
              prefectures.push({
                value: el.id,
                text: el.name,
              });
            });

            _this.prefectures.options = prefectures;

            _this.prefectures.loader = false;
          })
          .catch((error) => {
            console.log(error);
          });
        },
        setTags() {
          this.tags.loader = true;

          let tagList = this.usersData.tags;
          let tags = [];
          tags.push({
            value: null,
            text: 'ユーザタグを選択してください',
          });
          tagList.forEach((el) => {
            tags.push({
              value: el.id,
              text: el.name,
            })
          });

          this.tags.options = tags;

          this.tags.loader = false;
        },
        formatDate(date) {
          return moment(date).format('YYYY-MM-DD');
        },
        formatPrefectures(prefecture) {
          let _this = this;

          globalService_GetAllPrefectures()
            .then((response) => {
              let data = response.data;

              data.forEach((el) => {
                if (el.id === parseInt(prefecture)) {
                  _this.prefecture = el.name;
                }
              });
            })
            .catch((error) => {
              console.log(error);
            });
        },
        updateUserProfile(e) {
          let _this = this;
          //let accessToken = localStorage.getItem('_accessToken');
          let userID = location.pathname.split('/admin/users/edit/')[1];

          e.preventDefault();

          _this.formMessage = null;

          _this.$store.state.modalLoaderMessage = '保存中';
          _this.$bvModal.show('modal-loader');

          let accessToken = localStorage.getItem('_accessToken');

          if (_this.firstName !== null) {
            if (_this.lastName !== null) {
              if(_this.email !== null) {
                if (_this.birthDate !== null) {
                  if (_this.gender !== null) {
                    if (_this.prefectures.value !== null) {
                      if(_this.is_invoice_payment != null) {
                      adm_userService_UpdateUserProfile({
                        id: _this.usersData.id,
                        first_name: _this.firstName,
                        middle_name: null,
                        last_name: _this.lastName,
                        email: _this.email,
                        tel: _this.tel,
                        company_name: _this.company_name,
                        birth_date: _this.formatDate(_this.birthDate),
                        gender: _this.gender,
                        prefectures: _this.prefectures.value,
                        tag_id: _this.tags.value,
                        is_invoice_payment: _this.is_invoice_payment,
                        token: accessToken,
                      })
                      .then(() => {
                        setTimeout(() => {
                          _this.$bvModal.hide('modal-loader');
                          _this.$bvModal.show('user-profile-update-success-modal');
                        }, 500);
                      })
                      .catch((error) => {
                        console.log(error);

                        _this.formMessage =
                          'プロフィールの更新中にエラーが起こりました。再度お試しください。';

                        setTimeout(() => {
                          _this.$bvModal.hide('modal-loader');
                        }, 500);
                      });
                    }else {
                      _this.formMessage = 'is_invoice_payment is required.';

                      setTimeout(() => {
                        _this.$bvModal.hide('modal-loader');
                      }, 500);
                    }
                  } else {
                    _this.formMessage = 'Prefectures is required.';

                    setTimeout(() => {
                      _this.$bvModal.hide('modal-loader');
                    }, 500);
                  }
                } else {
                  _this.formMessage = 'Gender is required.';

                  setTimeout(() => {
                    _this.$bvModal.hide('modal-loader');
                  }, 500);
                }
              } else {
                _this.formMessage = 'Birthdate is required.';

                setTimeout(() => {
                  _this.$bvModal.hide('modal-loader');
                }, 500);
              }
            }else {
              _this.formMessage = 'email is required.';

              setTimeout(() => {
                _this.$bvModal.hide('modal-loader');
              }, 500);
            }
          } else {
            _this.formMessage = 'last name is required.';

            setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
            }, 500);
          }
        } else {
          _this.formMessage = 'First name is required.';

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        }
      },
    }
  }
</script>